.timeline-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 80vh;
  overflow-y: auto;
  background-color: #f5e3e0;
  padding: 20px;
  box-sizing: border-box;
}

.timeline {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px 0;
}

.event {
  font-family: 'HakgyoansimDunggeunmisoTTF-R';
  position: relative;
  width: calc(50% + 100px);
  margin: 20px 0; /* 상하 간격 조정 */
}

.event.left {
  float: left;
  text-align: right;
}

.event.right {
  float: right;
  text-align: left;
}

.date-content-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
}

.date {
  font-family: 'HakgyoansimDunggeunmisoTTF-B';
  font-weight: bold;
  color: #555;
  margin: 0;
  line-height: 1.2;
  margin-bottom: -30px;
  margin-top:30px;
}

.content {
  background-color: #ffffff;
  padding: 5px;
  border-radius: 8px;
  word-wrap: break-word;
  margin-top: 5px; /* 날짜와 내용 사이의 간격 추가 */
  line-height: 1.2;
}

.timeline::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 3px;
  background-color: #97b2c8;
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .timeline {
    border-left: none;
  }

  .timeline::before {
    left: 50%;
  }

  .event {
    width: 100%;
    float: none;
    text-align: center;
    margin: 20px 0; /* 모바일에서 상하 간격 조정 */
  }

  .date-content-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .date, .content {
    margin-bottom: 2px; /* 모바일에서 날짜와 내용 간격 조정 */
  }
  
  .date {
    margin-bottom: -30px;
    margin-top:30px;
  }

  .content {
    margin-top: 2px; /* 모바일에서 내용 상단 간격 조정 */
  }
}