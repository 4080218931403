.bucket-list-wrapper {
  display: block;
  padding: 50px;
  background-color: #f9f5f1;
}

.bucket-list-container {
  margin-bottom: 30px;
  padding: 10px;
  background-color: #fff5e1;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #5a5a5a;
}

h1 {
  font-family: 'HakgyoansimDunggeunmisoTTF-B', Courier, monospace;
  font-size: 1.6rem !important;
  color: #5f4c4b;
}

ul {
  font-family: 'HakgyoansimDunggeunmisoTTF-R', Courier, monospace;
  list-style-type: none;
  padding-left: 0;
}

li {
  padding: 10px 0;
  border-bottom: 1px solid #d4c4b0;
  font-size: 1.2rem;
}

@media (min-width: 769px) {
  .bucket-list-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .bucket-list-container {
    width: 43%; 
    margin-bottom: 30px;
  }
  
  h1 {
    font-size: 2.5rem !important;
  }
}