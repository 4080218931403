/* 기본적인 컨테이너 스타일 */
.result-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
  background-color: #f9f5f1; /* 부드러운 크림색 배경 */
  text-align: center;
  padding: 20px;
  box-sizing: border-box; /* 패딩을 포함한 높이 계산 */
}

/* 타이틀 스타일 */
.result-title {
  font-family: 'HakgyoansimDunggeunmisoTTF-B', Courier, monospace;
  font-size: 2.5rem;
  font-weight: bold;
  color: #5f4c4b; /* 고급스러운 브라운 계열 */
  margin-bottom: 1.5rem;
  margin-top: 1rem; /* 타이틀 위쪽 여백 추가 */
}

/* 설명 텍스트 스타일 */
.result-description {
  font-family: 'HakgyoansimDunggeunmisoTTF-R', Courier, monospace;
  font-size: 1.25rem;
  color: #5a5a5a; /* 다소 어두운 톤의 회색 */
  margin-bottom: 1rem;
  line-height: 1.6;
}

/* 버튼 스타일 */
.return-button {
  padding: 0.75rem 2rem;
  font-family: 'HakgyoansimDunggeunmisoTTF-R', 'Courier New', Courier, monospace;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff; /* 텍스트 색상을 밝게 */
  background-color: #5f4c4b; /* 다크 브라운 배경색 */
  border: 2px solid #d4c4b0; /* 테두리 색상을 고급스럽게 */
  border-radius: 30px; /* 둥근 버튼 */
  cursor: pointer;
  transition: all 0.3s ease;
}

/* 버튼 hover 효과 */
.return-button:hover {
  background-color: #d4c4b0; /* 밝은 베이지톤으로 hover 효과 */
  color: #5f4c4b; /* hover 시 버튼 글씨 색상을 브라운으로 변경 */
  border-color: #5f4c4b; /* 테두리 색상 변경 */
}

/* 기본 이미지 스타일 */
.result-image {
  width: 100%;
  max-width: 400px; /* 이미지가 너무 커지지 않도록 최대 너비 설정 */
  height: auto; /* 비율을 유지하면서 이미지 크기 조정 */
  margin: 20px auto; /* 이미지 주위에 여백 추가 */
  display: block; /* 중앙 정렬을 위해 block으로 설정 */
}

/* 반응형 디자인 */
@media (max-width: 768px) {
  .result-title {
    font-size: 2rem;
  }

  .result-description {
    font-size: 1.1rem;
  }

  .return-button {
    font-size: 1rem;
    margin-top: 1.5px;
  }
  .result-image {
    width: 70%; /* 모바일 화면에서는 80%로 조정 */
    max-width: 300px; /* 모바일에서 최대 너비 제한 */
  }
}

/* 데스크톱 스타일 (768px 이상) */
@media (min-width: 769px) {
  .result-image {
    width: 50%; /* 데스크톱에서는 화면 너비의 50%로 조정 */
    max-width: 400px; /* 최대 너비를 400px로 제한 */
  }
}