/* src/App.css */
.app {
  text-align: center;
}

.navbar {
  background-color: #f3d6d0;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.nav-links {
  list-style: none;
  display: flex;
  justify-content: space-around;
  padding: 0;
  margin: 0;
}

.nav-links li {
  display: inline;
}

.nav-links a {
  text-decoration: none;
  font-size: 0.8rem;
  color: #4a4a4a;
  font-weight: bold;
}

.nav-links a:hover {
  color: #6b6b6b;
}

.content {
  margin-top: 50px;
  padding: 20px;
}


body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f8f5f1;
  color: #4a4a4a; 
  line-height: 1.6;
}

.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h1, h2, h3, h4, h5, h6 {
  color: #6b5b95; 
  margin-bottom: 1.5rem;
  font-family: 'Georgia', serif;
}

h1 {
  font-size: 2.5rem;
  font-weight: bold;
}

h2 {
  font-size: 2rem;
}

p {
  font-size: 1.1rem;
  color: #4b4b4b;
}

a {
  color: #ff6f61; 
  text-decoration: none;
}

a:hover {
  color: #ff9671;
  transition: color 0.3s ease;
}


button {
  background-color: #ffeead;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  color: #4b4b4b;
  cursor: pointer;
}

button:hover {
  background-color: #ffcc5c;
  transition: background-color 0.3s ease;
}

input, textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  background-color: #fffbe7;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  .container {
    padding: 10px;
  }
}