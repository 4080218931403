/* 전체 폰트 설정 */
body {
  font-family: 'HakgyoansimBadasseugiTTF-L', sans-serif; /* 전체 폰트 설정 */
}

/* 편지 컨테이너 스타일 */
.letter-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 게시물 카드 스타일 */
.bulletin-card {
  background-color: #ffffff;
  padding: 30px;
  margin: 30px;
  margin-bottom: 50px;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.3); /* 약한 그림자 바운드 효과 추가 */
  transition: transform 0.2s; /* 확대 효과 */
}

.bulletin-card:hover {
  transform: scale(1.03); /* 게시글 확대 효과 */
}

.bulletin-card h3 {
  color: #555555;
}

.bulletin-card img {
  width: 100%;
  height: auto;
}

/* 게시물 작성 버튼 스타일 */
.create-post-button {
  background-color: #e0d4c3; /* 파스텔톤 유지 */
  border: none;
  padding: 10px 15px;
  margin: 10px;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
/* create-post-banner 스타일 */
.create-post-banner {
  background-color: #4A4A4A; /* 단정한 회색 톤 */
  color: white;
  border: none;
  padding: 15px;
  width: 100%; 
  max-width: 1200px;
  margin: 10px auto;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s ease;
  border-radius: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

/* 호버 시 색상 변화 */
.create-post-banner:hover {
  background-color: #3A3A3A; /* 더 어두운 회색 */
}

.create-post-button:hover {
  background-color: #d1bfa6; /* 약간 어두운 색으로 변환 */
}

/* 확장된 게시물 */
.expanded-post {
  padding-top: 20px;
  padding-bottom: 15px;
  background-color: #f9f8f6;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.expanded-post p {
  font-family: 'HakgyoansimBadasseugiTTF-L';
  text-align: left;
  padding: 15px;
}

.expanded-post img {
  width: 60%;
  height: auto;
  margin-bottom: 15px;
}
.expanded-post button {
  background-color: #e0d4c3;
  border: none;
  padding: 10px 15px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 0;
}
/* 댓글 섹션 */
.comments-section {
  background-color: #ffffff; 
  margin-top: 30px;
  max-width:1000px;
  width: 100%;
  padding-bottom: 5px;
}


.comments-section ul li {
  margin-top:5px;
  font-size: 16px;
  margin-left: 12px;
  margin-right:10px;
  padding: 5px 0; /* 댓글 사이 간격 조정 */
  text-align: left; /* 기본적으로 좌측 정렬 */
}

/* 댓글 작성 칸 */
.comments-section input {
  width: calc(100% - 42px); /* 경계선 여유 공간 고려 */
  margin-left: 6px;
  border: 1px solid #e0d4c3;
}

/* 댓글 버튼 스타일 */
.comments-section button {
  background-color: #e0d4c3;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 0;
}

.comments-section button:hover {
  background-color: #d1bfa6;
}

/* 모달 오버레이 및 내용 */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 150px;
  width: 60%;
  overflow: auto;
}

.modal-content input[type="text"],
.modal-content textarea {
  width: calc(100% - 40px); /* 경계선 여유 공간 고려 */
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #e0d4c3;
  border-radius: 5px;
  font-family: 'HakgyoansimBadasseugiTTF-L';
  box-sizing: border-box;
  resize: none;
}

/* 팝업 오버레이 스타일 */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* 어두운 배경 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* 다른 요소들 위에 표시 */
}

/* 팝업 스타일 */
.popup {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 90%; /* 화면 너비의 90% */
  box-sizing: border-box; /* 패딩과 보더를 포함하여 너비 계산 */
}

/* 입력 필드 스타일 */
.popup input[type="text"],
.popup textarea {
  width: calc(100% - 20px); /* 경계선 여유 공간 고려 */
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #e0d4c3;
  border-radius: 5px;
  font-family: 'HakgyoansimBadasseugiTTF-L';
  box-sizing: border-box; /* padding, border 포함하여 계산 */
  resize: none; /* 텍스트 영역 크기 조절 비활성화 */
}

/* 버튼 스타일 */
.popup button {
  background-color: #e0d4c3;
  border: none;
  padding: 10px 15px;
  margin-right: 10px;
  font-family: 'HakgyoansimBadasseugiTTF-L';
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0;
}

.popup button:hover {
  background-color: #d1bfa6; /* 버튼 호버 효과 */
}

input[type="file"] {
  margin-top: 10px;
  padding: 5px;
  display: block;
}

@media (max-width: 768px) {
  .expanded-post p {
    font-size: 10px;
  }
  
  .comments-section ul li {
    font-size: 10px;
  }
}