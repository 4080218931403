/* src/components/Quiz.css */

/* 기본적인 컨테이너 스타일 */
.quiz-container, .ques-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30vh;
  background-color: #f9f5f1; /* 부드러운 크림색 배경 */
  text-align: center;
  padding: 20px;
  box-sizing: border-box; /* 패딩을 포함한 높이 계산 */
}

/* 타이틀 스타일 */
.quiz-title, .ques-title {
  font-family: 'HakgyoansimDunggeunmisoTTF-B', Courier, monospace;
  font-size: 2.5rem;
  font-weight: bold;
  color: #5f4c4b; /* 고급스러운 브라운 계열 */
  margin-bottom: 2.5rem;
  margin-top: 1rem; /* 타이틀 위쪽 여백 추가 */
}

/* 설명 텍스트 스타일 */
.quiz-description {
  font-family: 'HakgyoansimDunggeunmisoTTF-R', Courier, monospace;
  font-size: 1.25rem;
  color: #5a5a5a; /* 다소 어두운 톤의 회색 */
  margin-bottom: 1rem;
  line-height: 1.6;
}

/* 버튼 컨테이너 */
.button-container {
  display: flex;
  flex-direction: column;
}

/* 버튼 스타일 */
.custom-button, .start-button {
  padding: 15px 50px;
  font-family: 'HakgyoansimDunggeunmisoTTF-R','Courier New', Courier, monospace;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff; /* 텍스트 색상을 밝게 */
  background-color: #5f4c4b; /* 다크 브라운 배경색 */
  border: 2px solid #d4c4b0; /* 테두리 색상을 고급스럽게 */
  border-radius: 30px; /* 둥근 버튼으로 고급스러움 강조 */
  cursor: pointer;
  transition: all 0.3s ease;
}

/* 버튼 hover 효과 */
.custom-button:hover, .start-button:hover {
  background-color: #d4c4b0; /* 밝은 베이지톤으로 hover 효과 */
  color: #5f4c4b; /* hover 시 버튼 글씨 색상을 브라운으로 변경 */
  border-color: #5f4c4b; /* 테두리 색상 변경 */
}

/* 반응형 디자인 */
@media (max-width: 768px) {
  
  .quiz-container, .ques-container {
    height: 50vh;
  }
  .quiz-title, .ques-title {
    font-size: 23px !important;
    margin-bottom: 2rem;
  }

  .quiz-description {
    font-size: 1.1rem;
  }

  .custom-button, .start-button {
    font-size: 1rem;
    padding: 9px 40px;
  }
}