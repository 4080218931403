/* Google Font Import */
@import url('https://fonts.googleapis.com/css2?family=HakgyoansimDunggeunmisoTTF&display=swap');

/* 메인 컨테이너 스타일 */
.home-container {
  font-family: 'HakgyoansimDunggeunmisoTTF-B';
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 6vh; /* A의 변경사항 반영 */
  background-color: #f7f3e9;
  font-size: 30px;
  padding:20px;
  padding-bottom: 40px;
}

/* 제목 스타일 */
h1 {
  font-size: 50px !important;
  color: #4b5d67;
  margin-bottom: 1.5rem;
}

/* 날짜 스타일 */
p {
  font-size: 30px;
  color: #6c757d;
  margin-bottom: -15px;
}

/* 버튼 스타일 */
button {
  font-family: 'HakgyoansimDunggeunmisoTTF-R';
  background-color: #a3d2ca; /* 파스텔톤 민트 색상 */
  color: white;
  border: none;
  border-radius: 7px;
  padding: 15px 30px;
  font-size: 1.5rem;
  margin-top: 40px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #89c0b4; /* 호버 시 조금 더 진한 파스텔톤 */
}

/* 팝업창 스타일 */
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  background: #ffffff;
  padding: 30px; /* A의 padding 반영 */
  padding-right: 0; /* 음수 패딩 제거 */
  border-radius: 15px; /* A에서 반영된 값 */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* A에서 반영된 그림자 */
  width: 90%; /* A의 변경사항 반영 */
  max-width: 600px; /* A의 변경사항 반영 */
  text-align: center;
  position: relative;
}

/* 팝업 닫기 버튼 스타일 */
.close-popup {
  position: absolute;
  top: 10px;
  right: 50px;
  cursor: pointer;
  font-size: 1.5rem;
  color: #333;
}

.close-popup:hover {
  color: #ff5c5c;
}

/* 편지 상자 스타일 */
.letter-box {
  font-family: 'HakgyoansimDunggeunmisoTTF-R';
  width: 90%;
  max-width: 500px;
  height: 300px;
  background-color: #ffffff;
  border: 2px solid #e0d4c3;
  border-radius: 15px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  margin-top: 2rem;
  padding-right: -20px;
}

/* 이미지 스타일 */
.image {
  width: 100%;
  max-width: 400px; /* A와 B 모두 동일 */
  height: auto; /* 비율 유지 */
  margin: 20px auto; /* 여백 추가 */
  display: block; /* 중앙 정렬 */
}

/* 모바일 호환성 */
@media (max-width: 768px) {
  .letter-box {
    width: 80%;
  }

  .popup {
    width: 70%;
    padding: 15px;
  }

  .close-popup {
    right: 20px;
  }

  h1 {
    font-size: 30px !important;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 20px;
  }

  button {
    margin-top: 1.5rem;
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}